
import React from 'react';
import { Navigate } from 'react-router-dom';

import LandingPage from './LandingPage';
import { useSelector } from 'react-redux';


const LandingOrHome = () => {
  const session = useSelector(state => state.user);
 
  if (session && Object.keys(session).length > 0) {

    if (session.rol && session.rol.includes('user')) {
      return <Navigate to="/agendaUser" />;
    } else if (session.rol && session.rol.includes('admin')) {
      return <Navigate to="/home" />;
    } else {
      
      return <Navigate to="/error" />;
    }
  
  }

  return <LandingPage />;
};

export default LandingOrHome;