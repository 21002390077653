
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { getPacientes } from "../../redux/action";
import "react-calendar/dist/Calendar.css";

import Card from "../Card/Card";
import ReactCalendar from "react-calendar";
import NavAgenda from "../Agenda/navAgenda";
import "./agenda.css";
import musica from '../../assets/musica.mp4'

export default function Agenda() {
  useEffect(() => {
    AOS.init();
  }, []);

  const dispatch = useDispatch();
  const allPacientes = useSelector((state) => state.pacientes);

  useEffect(() => {
    dispatch(getPacientes());
  }, [dispatch]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showHeader, setShowHeader] = useState(true);

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setShowHeader((prevShowHeader) => !prevShowHeader);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Función de comparación para ordenar por "preparacion" -> "procedimiento" -> "recuperacion"
  const compareByEstado = (a, b) => {
    const estadosOrder = ["preparación", "procedimiento", "recuperación"];
    const aIndex = estadosOrder.indexOf(a.estado);
    const bIndex = estadosOrder.indexOf(b.estado);

    // Si a y b tienen el mismo estado, se mantiene su orden original
    if (aIndex === bIndex) return 0;

    // Se compara por el índice de los estados en el arreglo estadosOrder
    return aIndex - bIndex;
  };

  // Filtrar las cirugías por la fecha seleccionada
  const filteredPacientes = allPacientes.filter((el) => {
    const elDate = new Date(el.year, el.month - 1, el.day);
    return elDate.toDateString() === selectedDate.toDateString();
  });

  // Filtrar pacientes en "preparacion" y "procedimiento"
  const pacientesPreparacionProcedimiento = filteredPacientes.filter(
    (el) => el.estado !== "recuperación"
  );

  // Filtrar pacientes en "recuperacion"
  const pacientesRecuperacion = filteredPacientes.filter(
    (el) => el.estado === "recuperación"
  );

  // Ordenar pacientes en "preparacion" y "procedimiento" por hora y estado
  const pacientesPreparacionProcedimientoSorted = pacientesPreparacionProcedimiento.sort(
    (a, b) => {
      // Se ordena por hora y minuto
      const timeA = parseInt(a.hour) * 60 + parseInt(a.minute);
      const timeB = parseInt(b.hour) * 60 + parseInt(b.minute);
      const timeComparison = timeA - timeB;

      // Si los elementos tienen diferentes horas y minutos, se devuelve el resultado de la comparación de tiempo
      if (timeComparison !== 0) return timeComparison;

      // Si los elementos tienen la misma hora y minuto, se utiliza la función compareByEstado
      return compareByEstado(a, b);
    }
  );

  // Combinar ambas listas para mostrarlos en el orden deseado
  const combinedPacientes = pacientesPreparacionProcedimientoSorted.concat(
    pacientesRecuperacion
  );

  return (
    <div className="contenedor-agenda">
      <div className="containe-padre">
        <div className="calen">
        <audio src={musica} autoPlay onLoadedData={() => console.log("Audio loaded")} />
          <div className="calendar-container">
            <ReactCalendar
              onChange={handleDateChange}
              value={selectedDate}
              className="calendar"
              calendarType="US"
            />
          </div>
          <div className="nav-container">
            <NavAgenda/>
          </div>
        </div>
       
        </div>
      <br />
      <hr />
      <div className="vista-card">
        {combinedPacientes.length > 0 ? (
          combinedPacientes.map((el) => (
            <Card
              key={el.id}
              name={el.name}
              lastName={el.lastName}
              cirujanoId={
                el.cirujano
                  ? el.cirujano.name + " " + el.cirujano.lastName
                  : "Cirujano no disponible"
              }
              procedimientId={
                el.procedimiento
                  ? el.procedimiento.name
                  : "Procedimiento no disponible"
              }
              salaId={el.sala ? el.sala.name : "Sala no disponible"}
              habitacionId={el.habitacion ? el.habitacion.name : "Habitación no disponible"}
              day={el.day}
              month={el.month}
              year={el.year}
              hour={el.hour}
              minute={el.minute}
              estado={el.estado}
              tiempo={el.tiempo}
            />
          ))
        ) : (
          <p>No hay cirugías programadas para este día.</p>
        )}
        <hr />
      </div>
    </div>
  );
}
