import React from 'react'
import logo from "../../assets/logo1.png";
import './navAgenda.css'
export default function NavAgenda() {

    return (
        <div className='nav-agenda'>
            <div className='nav-agenda-img'>
                <img
                    src={logo}
                    alt="Img Not Found"
                    width="150px"
                    height="150px"
                />
            </div>
            <div className='buttom-a'>
                <a href="/home">
                    Volver
                </a>

            </div>

            
        </div>

    )
}
