import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/logo1.png";
import { logout } from "../../redux/action";
import './nabAgenda.css'
export default function NabAgenda() {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Obtén la función navigate

    const handleLogout = () => {
        // Llamar a la acción logout para cerrar sesión
        dispatch(logout());
        // Redireccionar al inicio de sesión o a la página de landing después de cerrar sesión
        // (ajusta el "to" en navigate según sea necesario)
        navigate("/"); // Utiliza la función navigate para redireccionar
    };
    return (
        <div className='nav-agenda'>
            <div className='nav-agenda-img'>
                <img
                    src={logo}
                    alt="Img Not Found"
                    width="150px"
                    height="150px"
                />
            </div>
            <div className='nav-button'>
                <button onClick={handleLogout}>Cerrar sesion</button>
            </div>
        </div>
    )
}
