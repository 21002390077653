
import { Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setSession } from './redux/action/index';
import LandingOrHome from './components/LandingPage/LandingOrHome';
import Agenda from './components/Agenda/Agenda';
import AgendaUser from './components/AgendaUser/AgendaUser';
import Home from './components/Home/Home';
import Register from './components/Registro/Register';
import ProtectedRoute from './components/PrivateRouter/ProtectedRoute';
// axios.defaults.baseURL='https://barbershopclub.hair/api3/';
// axios.defaults.baseURL = 'http://localhost:3001/api2/';
axios.defaults.baseURL='https://backperfect.store/api2/';


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchSession = async () => {
    try {
      const token = Cookies.get('session');

      if (!token) {
        navigate('/'); // Si no hay sesión, redirige a la página de inicio
        return;
      }

      const response = await axios.get('/getSession', {
        withCredentials: true,
        headers: {
          'x-user-session': JSON.stringify(token),
        },
      });

      if (response.status === 200) {
        dispatch(setSession(response.data.user));

        if (response.data.user.rol && response.data.user.rol.includes('admin')) {
          // Usuario con rol admin
          navigate('/home');
        } else {
          // Usuario con otro rol
          navigate('/agendaUser');
        }
      } else {
        Cookies.remove('session');
        dispatch(setSession({}));
      }
    } catch (error) {
      console.log(error);
      Cookies.remove('session');
      dispatch(setSession({}));
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  useEffect(() => {
    const handleTokenRefresh = (event) => {
      if (event.key === 'session' && event.newValue) {
        Cookies.set('session', event.newValue);
        fetchSession();
      }
    };

    window.addEventListener('storage', handleTokenRefresh);

    return () => {
      window.removeEventListener('storage', handleTokenRefresh);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingOrHome />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/registro" element={<Register />} />
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/agendaUser" element={<ProtectedRoute><AgendaUser /></ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;


// axios.defaults.baseURL='http://localhost:3001/';