import React from 'react'
import './paginado.css'

export default function PaginadoProfesionales({ itemCount, itemsPerPage, onPageChange }) {
    
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(itemCount / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav className="barra">
        <ul>
          {pageNumbers.map((number) => (
            <li className="number" key={number}>
              <a onClick={() => onPageChange(number)}>{number}</a>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
  