
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePacienteEstado, getDetail } from "../../../redux/action";
import './detail.css'

const DetallePaciente = ({ pacienteId }) => {
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.detail);
  const [nuevoEstado,setNuevoEstado]= useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // Estado para mostrar/ocultar la alerta de confirmación

  // console.log(detail);

  useEffect(() => {
    dispatch(getDetail(pacienteId));
  }, [dispatch, pacienteId]);

  if (!detail) {
    return <div>Cargando detalle del paciente...</div>;
  }
  
  const { name, lastName, id, cirujano, procedimiento, sala, habitacion, day, month, year, estado } = detail;
  // Función para manejar el cambio de estado
  const handleEstadoChange = async () => {
    // Mostrar la alerta de confirmación
    setShowConfirmation(true);
  };

  // Función para confirmar el cambio de estado
  const confirmEstadoChange = async () => {
    // Ocultar la alerta de confirmación
    setShowConfirmation(false);
    
    // Llama a la acción para cambiar el estado del paciente
    await dispatch(changePacienteEstado(pacienteId, nuevoEstado));
    
    // Actualiza el estado local
    setNuevoEstado("");
  };

  // Función para cancelar el cambio de estado
  const cancelEstadoChange = () => {
    // Ocultar la alerta de confirmación
    setShowConfirmation(false);
  };
  
  return (
    <div className="card-detalle-paciente">
      <div className="detail-paciente">
        <h2>Detalle del paciente</h2>
        <a className='button-cerrar-x' href="/home"> x </a>
      </div>
      <div className="detalle-nombre">
        <span>Nombre: {name}</span>
        <span>Apellido: {lastName}</span>
        <span>Identificación: {id}</span>
      </div>
      <div className="detalle-paciente-id">
        <p>Cirujano: {cirujano?.name && cirujano?.lastName ? cirujano.name + " " + cirujano.lastName : "Cirujano no disponible"}</p>
        <p>Procedimiento: {procedimiento?.name}</p>
        <p>{habitacion?.name}</p>
      </div>
      <div className="detalle-paciente-proce">
        <p>{sala?.name}</p>
        <p>Día: {day}</p>
        <p>Mes: {month}</p>
        <p>Año: {year}</p>
        <p>Estado: {estado}</p>
      </div>
      <div className="cambiar-estado">
        <select value={nuevoEstado} onChange={(e) => setNuevoEstado(e.target.value)}>
            <option value=" ">Seleccionar Estado</option>
            <option value="preparación">Preparación</option>
            <option value="recuperación">Recuperación</option>
            <option value="procedimiento">Procedimiento</option>
          </select>
          
          {/* Agrega un botón para cambiar el estado */}
          <button onClick={handleEstadoChange}>Cambiar </button>
          {showConfirmation && (
          <div className="confirmacion">
            <p>¿Estás seguro que quieres cambiar el estado?</p>
            <button onClick={confirmEstadoChange}>Sí</button>
            <button onClick={cancelEstadoChange}>Cancelar</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetallePaciente;


